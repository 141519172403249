import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({ controllerConfig }) => {
  const { wixCodeApi } = controllerConfig;

  const openCompareLightbox = (productId: string): boolean => {
    wixCodeApi.window.openLightbox('add-to-compare', { productId });
    return true;
  };

  return {
    async pageReady() {
      controllerConfig.setProps({
        onButtonClick: openCompareLightbox,
      });
    },
    exports: () => ({
      set resourceList(
        resourceList: { id: string; sku: string; isPreOrder: boolean }[],
      ) {
        controllerConfig.setProps({ resourceList });
      },
    }),
  };
};

export default createController;
